/* cyrillic-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_e66fe9';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_e66fe9 {font-family: '__Inter_e66fe9', '__Inter_Fallback_e66fe9';font-style: normal
}

:root {
  --primary: #fd8;
  --primary-accent: #a85;
  --highlight: #ea2;

  --general-border: #cb9;
  --general-spacing: #987;
  --general-background: #876;

  --ui-border: #ca7;
  --ui-background: #765;
  --ui-background-clear: #7656;
  --ui-background-darker: #654;

  --header-background: #544;
  --gradient-dark: #975;
  --gradient-light: #eca;
  --gradient-light-bright: #fea;

  --border-width: 2px;
  --border-radius: 10px;
  --border-radius-inner: 7px;

  --box-shadow: 0px 4px 10px 0px var(--ui-background);
  --box-shadow-darker: 0px 4px 10px 3px var(--ui-background-darker);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  text-wrap: wrap;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

/* NODES */
.react-flow__node {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.react-flow__node.selected,
.react-flow__node:focus {
  box-shadow: var(--box-shadow-darker);
  > * {
    border-color: var(--highlight);
  }
}

/* EDGES */
.react-flow__edge {
  pointer-events: none !important ;
}

.react-flow__edge .react-flow__edge-path {
  stroke: var(--ui-border);
  stroke-width: 3;
}

.react-flow__edge.animated path {
  stroke: var(--highlight);
  stroke-width: 5;
}

.dimmed-path {
  > * {
    opacity: 0.25;
  }
}

/* HANDLES */
.react-flow__handle {
  opacity: 0;
}

/* CONTROLS */
.react-flow__controls button {
  background-color: var(--ui-background-darker);
  border: var(--border-width) solid var(--ui-border);
  color: var(--primary);
}

.react-flow__controls button:hover {
  background-color: var(--ui-background);
}

.react-flow__controls button:first-child {
  border-radius: 5px 5px 0 0;
}

.react-flow__controls button:last-child {
  border-radius: 0 0 5px 5px;
}

.react-flow__controls button path {
  fill: var(--primary);
}

/* ATTRIBUTION */
.react-flow__attribution {
  display: none;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7e624512c3433db5-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d8fdc95d5a4284b0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7add1b04a8dbb074-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4e4687409e533403-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9714907d9a8e12c0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/96827bb3668ab7bf-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ee65d20c5e82dfb1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e195dd2ded485df3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b6db722c6886c2cd-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9b67ab375515cd6f-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e35c7314ac518ddc-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a3c201c07e8eb753-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/04fe87c30c4f76ea-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3a04115668d8070d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/13259ce60de2aa3e-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/79015435f1a55eef-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/388fb79562c9fc54-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5f4dfe83faee04f1-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/67e05ba3467b9f4d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/daa8cca6b52a70fa-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3a18fc8da1cdcd01-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/03b685511c0eaac3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9cf7d128be063d32-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/101c7b68f2d8b610-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1e8103c5d17beb1d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/91a88e0c5dd21dfa-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ee7e17a7bdd8636b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/699512af39861afa-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/83e4d81063b4b659-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/bd976642b4f7fd99-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/de9eb3a9f0fa9e10-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/44c3f6d12248be7f-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/71ba03c5176fbd9c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4a8324e71b197806-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7d8c9b0ca4a64a5a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7c53f7419436e04b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1c57ca6f5208a29b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cff529cd86cc0276-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7be645d133f3ee22-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5647e4c23315a2d2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3dbd163d3bb09d47-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/934c4b7cb736f2a3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/dfa8b99978df7bbc-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/42d52f46a26971a3-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a6d330d7873e7320-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a5b77b63ef20339c-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d117eea74e01de14-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/eb52b768f62eeeb4-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/627622453ef56b0d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e25729ca87cc7df9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/912a9cfe43c928d9-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/baf12dd90520ae41-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bbdb6f0234009aba-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f06116e890b3dadb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8fb72f69fba4e3d2-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_25fc88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0e4fe491bf84089c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_25fc88';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_25fc88 {font-family: '__Roboto_25fc88', '__Roboto_Fallback_25fc88'
}

/* cyrillic */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4504fe8c30e735d7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/1afacde6ce4192c2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6488cb122c613bd6-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/1c0e581dac0f50d0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ae10a7769dbeb408-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d13e3ced857caefe-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2787bbbfc07c54f9-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/85319a457a7ee337-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9caad8575e680d73-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/81088011e676dbf9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/92f3c28c5be63e05-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/01cb16ac968e6b9c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/5562bf03723ed61d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7cfd40fd04aab95f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/22c8ab8d8f4b2b3c-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/42bf9cb45dd5b837-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d58a18d720637beb-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3a25c6f5a710f790-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a55682a11993d94a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/223ea94b6a643086-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d2d201980a0cfcc1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3e22bb7b3f9295f2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/29cdb7e91cb43a9a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9889c29410942b0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3a80f8945b316caa-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0e506553d728d68f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/2818e9df2ba52922-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/074efd0cc4cda195-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d01750b3398d6b8f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4c6f11c6c6da4579-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4c848afb63766a30-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Spectral_17b686';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0c69c0da69c5cdd8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Spectral_Fallback_17b686';src: local("Times New Roman");ascent-override: 96.03%;descent-override: 41.99%;line-gap-override: 0.00%;size-adjust: 110.28%
}.__className_17b686 {font-family: '__Spectral_17b686', '__Spectral_Fallback_17b686'
}

